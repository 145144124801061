import type { InferGetServerSidePropsType } from 'next';
import type { Brand_CmsImageHeadlinerQuery, BrandQuery } from '../../types/generated/types';
import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { makeServerQueryClient } from '@dx-ui/framework-react-query';
import { BannerNotification } from '@dx-ui/osc-banner-notification';
import { Link } from '@dx-ui/osc-link';
import { Spinner } from '@dx-ui/osc-spinner';
import { Trans, useTranslation } from 'next-i18next';
import getConfig from 'next/config';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';

import { CmsImageHeadliner } from '../../components/CmsImageHeadliner/CmsImageHeadliner';
import { Head } from '../../components/Head/Head';
import { Headline } from '../../components/Headline/Headline';
import LoginFrame from '../../components/Login/Login';
import { REACT_QUERY_CONFIG } from '../../config/react-query';
import {
  serverSideBrand_CmsImageHeadlinerQuery,
  serverSideBrandQuery,
  useGuestHiltonHonorsNumberQuery,
} from '../../queries/generated/react-query';
import { auth } from '../../utils/auth';
import { ENABLE_TMTP_ALT_LOGIN } from '../../utils/constants/featureFlags';
import { GLOBAL_RESOURCE_BUNDLES } from '../../utils/constants/global-resource-bundles';
import { GO_HILTON_GUEST, GO_HILTON_PATH } from '../../utils/constants/go-hilton';
import { minimalLayout } from '../../utils/constants/layouts';
import { CONFIG_RULE_PATH } from '../../utils/constants/rules';
import {
  isFeatureEnabled,
  useServerSideFeatureToggles as serverSideFeatureToggles,
} from '../../utils/featureToggles';
import { redirect } from '../../utils/helpers/redirect';
import { show404NonTransPage } from '../../utils/helpers/show404NonTransPage';
import { getAssetUrl, getUrlHost } from '../../utils/helpers/urlBuilder';

import type { ImageLoaderProps } from 'next/legacy/image';
import type { AuthResponse } from '@dx-ui/framework-auth-provider';
import type { TFunction } from 'i18next';
import { serverSideProps } from '../../utils/helpers/serverSideProps';

const {
  publicRuntimeConfig: { DX_AUTH_API_CLIENT_URI, REACT_APP_LOGIN_URI },
} = getConfig();

const ImageLoader = ({ src, width, quality }: ImageLoaderProps) =>
  `${src}?w=${width}&q=${quality || 75}`;

const getRedirectURL = (language: string) => {
  const url = `${getUrlHost()}/${language}/go-hilton/find-hotels/`;
  return url;
};

const LoginForm: React.FC<{
  handleLogin: (data: AuthResponse) => void;
  frameSrc: string;
  isRedirecting: boolean;
  isGoHiltonGuestError: boolean;
  loading: boolean;
  language: string;
  t: TFunction<'go-hilton'>;
}> = ({ handleLogin, frameSrc, isGoHiltonGuestError, isRedirecting, loading, language, t }) => {
  if (isRedirecting) {
    return (
      <div className="container flex h-80 justify-center">
        <Spinner className="text-hilton" size="xl" />
      </div>
    );
  }

  if (!loading && !isGoHiltonGuestError) {
    return (
      <LoginFrame
        frameSrc={frameSrc}
        showJoinInfo={false}
        showResponsiveHeader={false}
        language={language}
        source="GoHilton"
        onLogin={handleLogin}
      />
    );
  }

  if (isGoHiltonGuestError && !loading) {
    return (
      <BannerNotification status="error">
        <Trans t={t} i18nKey="errorHeader.failed_go_hilton.body">
          <Link className="text-danger font-bold" url={`/${language}/locations/`} />
        </Trans>
      </BannerNotification>
    );
  }
  return null;
};

const GoHilton = ({
  enabledAltTmtpLogin,
  cmsImageHeadlinerData,
  goHiltonContent,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter();
  const { login } = useAuth();
  const language = router.locale || 'en';
  const [t] = useTranslation(['go-hilton', 'common']);
  const [isGoHiltonGuestError, setGoHiltonGuestError] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const [goHiltonGuestId, setGoHiltonGuestId] = useState<number | undefined>();

  const { data: guestData, isInitialLoading: loading } = useGuestHiltonHonorsNumberQuery(
    { language, guestId: goHiltonGuestId as number },
    { enabled: !!goHiltonGuestId }
  );

  useEffect(() => {
    void (async () => {
      if (guestData) {
        const userType = guestData?.guest?.hhonors?.packages.find((pack) =>
          GO_HILTON_GUEST.includes(pack.packageName)
        );
        if (userType) {
          await redirect(getRedirectURL(language), undefined, router);
        } else {
          setIsRedirecting(false);
          setGoHiltonGuestError(true);
        }
      }
    })();
  }, [guestData, language, router]);

  const frameSrc = enabledAltTmtpLogin
    ? `${DX_AUTH_API_CLIENT_URI}/en${REACT_APP_LOGIN_URI}alt/`
    : `${DX_AUTH_API_CLIENT_URI}/en${REACT_APP_LOGIN_URI}`;

  const handleLogin = useCallback(
    async (message: AuthResponse) => {
      setIsRedirecting(true);
      const guestId = message?.userInfo?.guestId as number;
      setGoHiltonGuestId(guestId);
      if (guestId) {
        await login({
          data: {
            userInfo: message.userInfo,
            tokenInfo: message.tokenInfo,
            remember: message.remember,
          },
        });
      }
    },
    [login]
  );

  const pageContent = goHiltonContent?.brand?.page?.fullWidthImage?.[0];

  const backgroundImage = pageContent?.imageCompound?.image?.variants?.filter(
    (imgVariants) => imgVariants.size === (isMobile ? 'xs' : 'md')
  );

  const goHiltonLogo = getAssetUrl('/svgs/logos/go-hilton_accessible-logo.svg');

  return (
    <>
      <Head
        title={t('go-hilton:title')}
        keywords={t('go-hilton:metaData.keywords')}
        description={t('go-hilton:metaData.description')}
      />
      <CmsImageHeadliner cmsImageHeadlinerData={cmsImageHeadlinerData} />
      <section
        className="mx-auto flex flex-col bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url("${backgroundImage?.[0]?.url}")` }}
      >
        <div className="container-fluid relative flex size-full flex-col items-center py-8 md:flex-row md:items-start md:justify-between md:py-14">
          <div className="mb-6 md:m-0">
            <Image
              src={goHiltonLogo}
              alt={t('go-hilton:headline')}
              loader={ImageLoader}
              width={147}
              height={120}
            />
          </div>
          <div className="bg-bg max-w-md self-center rounded px-4 py-11 pb-0 shadow-lg md:px-9">
            <Headline className="mb-3">
              <Trans t={t} i18nKey="go-hilton:unlockRates">
                <span className="text-hilton-alt" />
              </Trans>
            </Headline>
            <LoginForm
              frameSrc={frameSrc}
              handleLogin={handleLogin}
              isGoHiltonGuestError={isGoHiltonGuestError}
              isRedirecting={isRedirecting}
              language={language}
              loading={loading}
              t={t}
            />
          </div>
          <section className="bg-bg-inverse/80 before:bg-hilton-alt bottom-12 z-10 mt-8 max-w-md rounded-lg p-5 before:mb-4 before:block before:h-1 before:w-16 md:absolute md:left-2 md:mt-0 md:w-56 lg:absolute lg:left-8 lg:mt-0 lg:w-2/6 xl:left-10">
            <p className="text-text-inverse text-base font-bold">
              {pageContent?.shortDescription || ''}
              {pageContent?.link?.url ? (
                <Link
                  url={pageContent?.link?.url}
                  adaDescription={pageContent?.link?.adaDescription || ''}
                  isNewWindow={pageContent?.link?.isNewWindow || false}
                  className="text-text-inverse ml-1"
                >
                  {pageContent?.link?.label}
                </Link>
              ) : null}
            </p>
          </section>
        </div>
        <section className="bg-bg-inverse w-full py-2">
          <div className="container">
            <p className="text-text-inverse text-base">{pageContent?.caption}</p>
          </div>
        </section>
      </section>
    </>
  );
};

export const getServerSideProps = (async (context) => {
  const { req, res, locale } = context;
  const authClient = auth(req as unknown as Request);
  const queryClient = makeServerQueryClient({
    ...REACT_QUERY_CONFIG,
    serverResponse: res,
    authClient,
  });

  const show404 = await show404NonTransPage({ url: req.path, language: locale, queryClient });
  if (show404) return { notFound: true };

  const [featureToggles, cmsImageHeadlinerData, goHiltonContent] = await Promise.all([
    serverSideFeatureToggles(queryClient, [ENABLE_TMTP_ALT_LOGIN]),
    serverSideBrand_CmsImageHeadlinerQuery(queryClient, {
      language: locale,
      path: CONFIG_RULE_PATH[GO_HILTON_PATH],
    }).catch((e) => {
      // eslint-disable-next-line no-console
      console.log('Error in serverSideBrand_CmsImageHeadlinerQuery at go-hilton: ', e);
      return { brand: null } as Brand_CmsImageHeadlinerQuery;
    }),
    serverSideBrandQuery(queryClient, {
      language: locale,
      brandCode: 'HH',
      path: '/go-hilton/home/',
    }).catch((e) => {
      // eslint-disable-next-line no-console
      console.log('Error in serverSideBrandQuery at go-hilton: ', e);
      return {
        brand: null,
      } as BrandQuery;
    }),
  ]);

  const enabledAltTmtpLogin = isFeatureEnabled(featureToggles, ENABLE_TMTP_ALT_LOGIN);

  return serverSideProps({
    authClient,
    context,
    queryClient,
    pageProps: {
      enabledAltTmtpLogin,
      cmsImageHeadlinerData,
      goHiltonContent,
      layout: minimalLayout,
      metrics: {
        trackingData: {
          pageView: 'honorsPageView',
          pageData: {
            pageName: 'GoHiltonLogin',
            subSection: 'Brand',
            pageType: 'GoHiltonLogin',
            subSubSection: '',
          },
        },
      },
    },
    namespaces: [...GLOBAL_RESOURCE_BUNDLES, 'login', 'go-hilton'],
  });
}) satisfies GetServerSideProps;

export default GoHilton;
