import { useMemo } from 'react';

import { useSegments } from '../../hooks';

import type {
  Brand_CmsImageHeadliner,
  Brand_CmsImageHeadlinerQuery,
} from '../../types/generated/types';
type ImageHeadlinerProps = {
  cmsImageHeadlinerData?: Brand_CmsImageHeadlinerQuery;
};
export const CmsImageHeadliner = ({ cmsImageHeadlinerData }: ImageHeadlinerProps) => {
  const segments = useSegments();

  const imageHeadliner = cmsImageHeadlinerData?.brand?.page?.imageHeadliner?.[0];

  const filteredItems = useMemo(() => {
    if (!imageHeadliner) {
      return [];
    }
    return imageHeadliner?.items.reduce((acc, item) => {
      if (item?.segmentIds?.some((segmentId) => segments.includes(segmentId))) {
        acc.push({ ...item });
      }
      return acc;
    }, [] as Brand_CmsImageHeadliner.ImageHeadliner['items']);
  }, [imageHeadliner, segments]);

  const imageHeadlinerFmt = filteredItems?.[0];
  const content = useMemo(() => {
    if (!imageHeadlinerFmt) {
      return null;
    }

    const { link, image, headline } = imageHeadlinerFmt;
    const imageUrl = image?.variants?.find((v) => v.size === 'sm')?.url;

    return (
      <section className="bg-secondary text-bg relative w-full py-2" data-e2e="imageHeadliner">
        <div className="font-headline container flex items-center text-left sm:px-4 md:justify-center lg:px-20">
          {imageUrl ? (
            <picture className="mr-4 flex w-16 md:mr-6 md:w-24 ">
              <img
                className="inline-block max-h-12 w-16 md:max-h-16 md:w-24"
                src={imageUrl}
                alt={image?.altText || ''}
              />
            </picture>
          ) : null}
          <p className="text-base md:text-lg lg:text-2xl">
            <span>{headline}</span>
            {link?.url ? (
              <a
                className="ml-1 inline-block whitespace-nowrap text-sm font-bold underline sm:max-w-full md:inline md:max-w-none md:text-lg"
                rel="noreferrer"
                href={link?.url}
                target={link?.isNewWindow ? '_blank' : '_self'}
              >
                {link?.label}
              </a>
            ) : null}
          </p>
        </div>
      </section>
    );
  }, [imageHeadlinerFmt]);

  return content;
};
